import AgoraRTC from '@/plugins/AgoraRTC.js'

var client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
var remoteUsers = {};
var localTracks = {
  audioTrack: null
};
// 现在客户端选项
var options = {
  appid: null,
  channel: null,
  uid: null,
  token: null
};

// 连麦
async function join() {
  client.on("user-published", handleUserPublished);

  client.on("user-unpublished", handleUserUnpublished);
  
  // 加入一个频道并创建本地曲目，我们可以使用Promise.all同时运行它们
  [ options.uid, localTracks.audioTrack ] = await Promise.all([
    // 加入频道
    client.join(options.appid, options.channel, options.token || null, options.uid || null),
    // 使用麦克风和摄像头创建本地曲目
    AgoraRTC.createMicrophoneAudioTrack(),
  ]);
  
  // 将本地轨迹发布到通道
  await client.publish(Object.values(localTracks));
  console.log("publish success");
}

async function leave() {
  var track = localTracks.audioTrack;
  if (track) {
    track.stop();
    track.close();
    localTracks.audioTrack = undefined;
  }
  remoteUsers = {};
  // 离开频道
  await client.leave();
  console.log("client leaves channel success");
}

function handleUserPublished(user, mediaType) {
  const id = user.uid;
  remoteUsers[id] = user;
  subscribe(user, mediaType);
}

function handleUserUnpublished(user) {
  const id = user.uid;
  delete remoteUsers[id];
}

async function subscribe(user, mediaType) {
  // subscribe to a remote user
  await client.subscribe(user, mediaType);
  console.log("subscribe success");
  if (mediaType === 'audio') {
    user.audioTrack.play();
  }
}

export {
  options,
  leave,
  join
}